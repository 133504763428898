exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contests-js": () => import("./../../../src/pages/contests.js" /* webpackChunkName: "component---src-pages-contests-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-awards-js": () => import("./../../../src/pages/industry-awards.js" /* webpackChunkName: "component---src-pages-industry-awards-js" */),
  "component---src-pages-nods-js": () => import("./../../../src/pages/nods.js" /* webpackChunkName: "component---src-pages-nods-js" */),
  "component---src-templates-dummy-js": () => import("./../../../src/templates/dummy.js" /* webpackChunkName: "component---src-templates-dummy-js" */),
  "component---src-templates-fellow-js": () => import("./../../../src/templates/fellow.js" /* webpackChunkName: "component---src-templates-fellow-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-screening-js": () => import("./../../../src/templates/screening.js" /* webpackChunkName: "component---src-templates-screening-js" */)
}

